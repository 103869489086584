import { createSlice } from '@reduxjs/toolkit';
import { Event, EventDto, LiveEventStateChangeDto, Payload } from 'types';
import { mapEventData, mapEventPing } from 'utils';

export interface EventState {
  eventLoading: boolean;
  eventData: Event | null;
}

const initialState: EventState = {
  eventLoading: false,
  eventData: null,
};

const reducers = {
  toggleEventLoading: (state: EventState, { payload }: { payload: boolean }) => {
    state.eventLoading = payload;
  },
  setEventData: {
    prepare: (eventData: EventDto) => {
      return { payload: mapEventData(eventData) };
    },
    reducer: (state: EventState, { payload }: Payload<Event>) => {
      state.eventLoading = false;
      state.eventData = payload;
    },
  },
  updateEventData: {
    prepare: (eventPing: LiveEventStateChangeDto) => {
      return { payload: mapEventPing(eventPing) };
    },
    reducer: (state: EventState, { payload }: Payload<Partial<Event>>) => {
      const { name, eventInformation, checkpointRadius, logoSrc, logoRedirect, devices } = payload;
      if (name && state.eventData) {
        state.eventData.name = name;
      }
      if (eventInformation && state.eventData) {
        state.eventData.eventInformation = eventInformation;
      }
      if (checkpointRadius && state.eventData) {
        state.eventData.checkpointRadius = checkpointRadius;
      }
      if (logoSrc && state.eventData) {
        state.eventData.logoSrc = logoSrc;
      }
      if (logoRedirect && state.eventData) {
        state.eventData.logoRedirect = logoRedirect;
      }
      if (devices && state.eventData) {
        state.eventData.devices = devices;
      }
    },
  },
};

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers,
});

export const { toggleEventLoading, setEventData, updateEventData } = eventSlice.actions;
export default eventSlice.reducer;
