import { ObjectMarker, PlayerMarker } from 'components';
import { mapConfig } from 'components/Map/Map.config';
import { Polyline, TileLayer } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { AppSettings, ObjectPointTypes, Player, RaceObject, RecordIndex } from 'types';
import { getLocationsByObjectType } from 'utils';

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const renderObjects = (objectsData: RaceObject[], recordIndex: RecordIndex) => {
  const objects = objectsData
    .filter(({ records }) => records.length)
    .map((raceObject) => {
      return <ObjectMarker key={`object_${raceObject.id}`} {...raceObject} />;
    });

  const lines = renderLines(objectsData, recordIndex);

  return [...objects, ...lines];
};

export const renderLayer = (mapType: AppSettings['mapType']) => {
  switch (mapType) {
    case 'GoogleHybrid':
      return (
        <ReactLeafletGoogleLayer
          key={mapType}
          apiKey={googleMapsApiKey}
          detectRetina
          type="hybrid"
        />
      );
    case 'GoogleRoadmap':
      return (
        <ReactLeafletGoogleLayer
          key={mapType}
          apiKey={googleMapsApiKey}
          detectRetina
          type="roadmap"
        />
      );
    case 'GoogleSatellite':
      return (
        <ReactLeafletGoogleLayer
          key={mapType}
          apiKey={googleMapsApiKey}
          detectRetina
          type="satellite"
        />
      );
    case 'OsmLight':
    case 'OsmDark':
    case 'OsmRoadmap':
      return (
        <TileLayer
          key={mapType}
          attribution={mapConfig[mapType].attribution}
          maxNativeZoom={19}
          maxZoom={20}
          url={mapConfig[mapType].url}
        />
      );
    default:
      return <span />;
  }
};

export const renderLines = (objectsData: RaceObject[], recordIndex: RecordIndex) => {
  const startPointsObjectTypes = [ObjectPointTypes.StartPointsA, ObjectPointTypes.StartPointsB];
  const startPoints = getLocationsByObjectType(objectsData, startPointsObjectTypes, recordIndex);

  const finishPointsObjectTypes = [ObjectPointTypes.FinishPointsA, ObjectPointTypes.FinishPointsB];
  const finishPoints = getLocationsByObjectType(objectsData, finishPointsObjectTypes, recordIndex);

  const mixedPointsObjectTypes = [
    ObjectPointTypes.MixedPointStart,
    ObjectPointTypes.MixedPointFinish,
  ];
  const mixedPoints = getLocationsByObjectType(objectsData, mixedPointsObjectTypes, recordIndex);

  const pointsArrays = [startPoints, finishPoints, mixedPoints];
  return pointsArrays.map((arr, i) => (
    <Polyline
      key={`line_${i}`}
      color="red"
      fillOpacity={0.5}
      positions={arr.concat() as any}
      weight={4}
    />
  ));
};

export const renderTrails = (
  playersData: Player[],
  recordIndex: RecordIndex,
  trailLength: number,
) => {
  const trails = playersData
    .filter(({ isVisible, records }) => isVisible && records.length)
    .map(({ trailCords, color, id, records }) => {
      const index = recordIndex === 'live' ? records.length - 1 : recordIndex;
      const startIndex = index - trailLength;
      const positions = trailCords.slice(startIndex < 0 ? 0 : startIndex, index + 1);
      return <Polyline key={`trail_${id}`} color={color} positions={positions as any} weight={1} />;
    });
  return trails;
};

export const renderPlayers = (playersData: Player[]) => {
  const players = playersData
    .filter(({ isVisible, records }) => isVisible && records.length)
    .map((player) => {
      return <PlayerMarker key={`player_${player.id}`} {...player} />;
    });

  return players;
};
